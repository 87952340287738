import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        // Login query to get new set of tokens
        login: builder.mutation({
            query: credentials => ({
                "url": "/auth/login",
                method: "POST",
                body: {
                    "email": credentials.user,
                    "password": credentials.pwd
                }
            })
        }),

        // Refresh query to be used on page loads
        refresh: builder.mutation({
            query: () => ({
                "url": "/auth/refresh",
                method: "GET",
            })
        }),

        // Registration query
        register: builder.mutation({
            query: userData => ({
                "url": "/auth/register",
                method: "POST",
                body: {
                    "email": userData.user,
                    "password": userData.pwd
                }
            })
        }),

        // Logout query
        logOutQuery: builder.mutation({
            query: () => ({
                "url": "/auth/logout",
                method: "GET",
            })
        }),

        // Get Api Key Query
        getApiKey: builder.mutation({
            query: () => ({
                "url": "/auth/api-key",
                method: "get"
            })
        }),

        // Create Api Key Query
        createApiKey: builder.mutation({
            query: () => ({
                "url": "/auth/api-key",
                method: "post"
            })
        }),

        // Login query to get new set of tokens
        subscribe: builder.mutation({
            query: subscription => ({
                "url": "/subscription",
                method: "POST",
                body: {
                    "name": subscription.name,
                    "type": subscription.type,
                    "paymentMethod": subscription.paymentMethod
                }
            })
        }),
    })
})

export const {
    useLoginMutation,
    useRefreshMutation,
    useRegisterMutation,
    useLogOutQueryMutation,
    useGetApiKeyMutation,
    useCreateApiKeyMutation,
    useSubscribeMutation
} = authApiSlice