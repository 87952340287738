import { Link } from "react-router-dom"

const Public = () => {

    const content = (
        <section className="public">
            <header>
                <h1>Example Title</h1>
            </header>
            <main>
                <p>This is an example 'public' page that does not require any logging in</p>
            </main>
            <footer>
                <Link to="/login">Login</Link> <br/>
                <Link to="/register">Register</Link> <br/>
                <Link to="/projects">Projects</Link> <br/>
            </footer>
        </section>

    )
    return content
}
export default Public