import { createSlice } from "@reduxjs/toolkit";


// Define the initial state and create the slice
const initialState = {user: null, token: null, apiKey: null}
const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setCredentials: (state, action) => {
            const  {user, accessToken } = action.payload
            state.user = user
            state.token = accessToken
        },
        logOut: (state, action) => {
            state.user = null
            state.token = null
        },
        setApiKey: (state, action) => {
            const {apiKey} = action.payload
            state.apiKey = apiKey
        }
    }
})

// Export the reducer actions (will edit what is stored in state)
export const {
    setCredentials,
    logOut,
    setApiKey
} = authSlice.actions
export default authSlice.reducer

// Export functions for getting certain attributes from state
export const selectCurrentUser = (state) => {return state.auth.user}
export const selectCurrentToken = (state) => {return state.auth.token}
export const selectApiKey = (state) => {return state.auth.apiKey}