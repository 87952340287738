import { Box, Button, TextField } from '@mui/material'
import React, { useState } from 'react'

function ProjectHomePage() {
    
    const [projectFormData, setProjectFormData] = useState({})

    const handleProjectSubmit = (e) => {
        e.preventDefault()
        console.log(e)
    }

  return (
    <>
    <h1>Projects</h1>
    <form onSubmit={handleProjectSubmit}>
      <TextField value={projectFormData?.name} id="standard-basic" label="Project Name" variant="standard"  required/>
      <TextField value={projectFormData?.email} id="standard-basic" label="Email" variant="standard" />
      <Button type="submit">Add Project</Button>
    </form>
    </>
  )
}

export default ProjectHomePage