import {
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useSubscribeMutation } from "../../features/auth/authApiSlice";

function PaymentForm() {

  // State variables for payment
  const [name, setName] = useState("");

  // Stripe specific hooks
  const stripe = useStripe();
  const elements = useElements();

  // RTK Hooks
  const [subscribe, { isLoading }]= useSubscribeMutation()

  // Helper function that will attempt to add a subscription for that user
  const createSubscription = async () => {
    try {

      // Create a stripe payment method
      const paymentMethod = await stripe.createPaymentMethod({
        card: elements.getElement("card"),
        type: "card",
      });

      // Attempt to make a backend request to subscription
        const response = await subscribe({ 
        name: name,  
        paymentMethod: paymentMethod.paymentMethod.id, 
        type: "premium"}).unwrap()

      const confirm = await stripe.confirmCardPayment(response?.clientSecret);
      if (confirm.error) {
        return alert("Payment unsuccessful!");
      }
      alert("Payment Successful! Subscription active.");
    } catch (err) {
      console.error(err);
      alert("Payment failed! " + err.message);
    }
  };

  return (
    <div>
      Name On Card:{" "}
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <br />
      <br />
      <CardElement />
      <br />
      <button onClick={createSubscription}>Subscribe</button>
    </div>
  );
}

export default PaymentForm;