import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentToken, setCredentials } from '../../features/auth/authSlice'
import { useLoginMutation } from '../../features/auth/authApiSlice'

const LoginPage = () => {

    // UI specific hooks
    const userRef = useRef()
    const errRef = useRef()
    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState('')

    // Hooks for navigation and Redux
    const navigate = useNavigate()
    const [login, { isLoading }] = useLoginMutation()
    const dispatch = useDispatch()

    // Focus on login form on load
    useEffect(() => {
        userRef.current.focus()
    }, [])

    // Set the err message to null on load, remove mesage if user/password changes
    useEffect(() => {
        setErrMsg('')
    }, [user, pwd])

    /**
     * Will attempt to make a login request.
     * @param {*} e : Event on submit
     */
    const handleSubmit = async (e) => {
        e.preventDefault()

        try {

            // If login was successfull, set the credentials and reset the user, password and navigate to the private page
            const userData = await login({ user, pwd }).unwrap()
            dispatch(setCredentials({ ...userData, user }))
            setUser('')
            setPwd('')
            navigate('/welcome')

        // TODO Get the error response from the server
        } catch (err) {
            setErrMsg("Something bad happened")
            errRef.current.focus();
        }
    }

    // Sets the components state on input
    const handleUserInput = (e) => setUser(e.target.value)
    const handlePwdInput = (e) => setPwd(e.target.value)
    const token = useSelector(selectCurrentToken)

    const content = isLoading ? <h1>Loading...</h1> : (
        <section className="login">
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <h1>Login</h1>

            <form onSubmit={handleSubmit}>
                <label htmlFor="username">Email:</label>
                <input
                    type="text"
                    id="username"
                    ref={userRef}
                    value={user}
                    onChange={handleUserInput}
                    autoComplete="off"
                    required
                />

                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    onChange={handlePwdInput}
                    value={pwd}
                    required
                />
                <button>Sign In</button>
            </form>
        </section>
    )

    return content
}
export default LoginPage