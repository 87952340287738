import { Routes, Route } from 'react-router-dom'
import Layout from './Components/Layout/Layout'
import Public from './Pages/Public/Public'
import LoginPage from './Pages/LoginPage/LoginPage'
import RequireAuth from './features/auth/RequireAuth'
import PrivatePage from './Pages/PrivatePage/PrivatePage'
import RegisterPage from './Pages/RegisterPage/RegisterPage'
import ProjectHomePage from './Pages/ProjectHomePage/ProjectHomePage'


function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        
        {/* public routes */}
        <Route index element={<Public />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />

        {/* protected routes */}
        <Route element={<RequireAuth />}>
          <Route path="welcome" element={<PrivatePage/>} />
          <Route path="projects" element={<ProjectHomePage/>} />
        </Route>

      </Route>
    </Routes>
  )
}

export default App;