import { Elements } from '@stripe/react-stripe-js'
import React from 'react'
import PaymentForm from './PaymentForm'
import { loadStripe } from '@stripe/stripe-js'

function StripeContainer() {

    const PUBLIC_KEY = "pk_test_51KiTOoDNY4nOmcndAnL1hrJeYxN6elHpsWka9aocgZP09TQzkInbmapk9pYglkr6aLBACUuRkujzjXvN5vwO1S5h004aVr5Oqt"
    const stripePromise = loadStripe(PUBLIC_KEY)

  return (
    <Elements stripe={stripePromise}>
        <PaymentForm />
    </Elements>
  )
}

export default StripeContainer