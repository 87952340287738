import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser, selectCurrentToken, logOut, selectApiKey, setCredentials, setApiKey } from '../../features/auth/authSlice'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useCreateApiKeyMutation, useGetApiKeyMutation, useLogOutQueryMutation } from '../../features/auth/authApiSlice'
import StripeContainer from '../../Components/Payment/StripeContainer'

function PrivatePage() {

  // Get the User's auth info
  const user = useSelector(selectCurrentUser)
  const token = useSelector(selectCurrentToken)
  const apiKey = useSelector(selectApiKey)

  // Define text to render
  const welcome = user ? <><h1>{`Welcome ${user}!`}</h1><br/><h1>This is an example of a private page that requires auth</h1></> : <h1>"Welcome!"</h1>

  // Helpful hooks for logging out an navigating
  const [logOutQuery] = useLogOutQueryMutation()
  const [getApiKey] = useGetApiKeyMutation()
  const [createApiKey] = useCreateApiKeyMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const handleLogoutClick = async () => {
    try{

      // TODO Figure out why this does not navigate to home
      await logOutQuery().unwrap()
      navigate("/")
      dispatch(logOut())

    } catch {

      // TODO render an error message
      alert("Logout Failed")
    }
  }

  const handleGetApiKey = async () => {
    try{
      const recievedApiKey = await getApiKey().unwrap()
      const credentialPayload = {apiKey: recievedApiKey?.api_key}
      dispatch(setApiKey(credentialPayload))
    } catch {
      alert("Failed to get api key")
    }
  }

  const handleCreateApiKey = async () => {
    try{
      const recievedApiKey = await createApiKey().unwrap()
      const credentialPayload = {apiKey: recievedApiKey?.api_key}
      dispatch(setApiKey(credentialPayload))
    } catch {
      alert("Failed to create api key")
    }
  }

  return (
    <div>
        {welcome}
        <p>Token : {apiKey}</p>
        <button onClick={() => handleLogoutClick()}>Logout</button>
        <button onClick={() => handleGetApiKey()}>Get API Key</button>
        <button onClick={() => handleCreateApiKey()}>Create API Key</button>
        <p><Link to="/">home</Link></p>
        <StripeContainer/>
    </div>
  )
}

export default PrivatePage